import React from "react";
import { Flex, Text, Divider } from "@chakra-ui/react";

const NavBar: React.FC = () => {
  return (
    <>
      <Flex
        as="nav"
        justifyContent="space-between"
        alignItems="center"
        py={{ base: 1, md: 2 }}
      >
        <Text fontSize={{ base: "xl", sm: "2xl", md: "2xl" }}>
          Agnostech AI
        </Text>
        <Text
          fontSize={{ base: "lg", sm: "md", md: "md" }}
          fontWeight={300}
          as={"button"}
          onClick={() => {
            const featuresSection = document.querySelector("#contact-us");
            if (featuresSection) {
              featuresSection.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          Contact Us
        </Text>
      </Flex>
      <Divider borderColor={"black"} />
    </>
  );
};

export default NavBar;
