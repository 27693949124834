import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import LandingPage from "./pages/LandingPageV2";
import { ChakraProvider } from "@chakra-ui/react";
import "./fonts/the-youngest-serif-display.ttf";
// import AdminLayout from "./components/AdminLayout";
// import Agents from "./components/Agents";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <div>404 | Page Not Found</div>,
  },
  // {
  //   path: "admin",
  //   element: <AdminLayout />,
  //   children: [
  //     {
  //       path: "homepage",
  //       element: <div>Home Page</div>,
  //     },
  //     {
  //       path: "agents",
  //       element: <Agents />,
  //     },
  //   ],
  // },
]);

const App: React.FC = () => {
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
};

export default App;
